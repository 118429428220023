import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'

import './Content.scss'

const Content = ({ children, noPadding, className }) => (
  <div className={`component-content ${noPadding ? 'component-content--without-padding' : ''} ${className}`}>
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>{children}</Col>
      </Row>
    </Container>
  </div>
)

Content.propTypes = {
  children: PropTypes.node,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
}

export default Content
