import React from 'react'

import Content from '../components/Content'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const DatenschutzPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Content>
      <h1>Datenschutzerklärung</h1>
      <h2>Einleitung</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen
        Daten (nachfolgend auch kurz als &quot;Daten&quot; bezeichnet) wir zu welchen Zwecken und in welchem Umfang
        verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener
        Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in
        mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z. B. unserer Social-Media-Profile
        (nachfolgend zusammenfassend bezeichnet als &quot;Onlineangebot&quot;).
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 21. Februar 2021</p>
      <h2>Inhaltsübersicht</h2>
      <ul className="index">
        <li>
          <a className="index-link" href="#m14">
            Einleitung
          </a>
        </li>
        <li>
          <a className="index-link" href="#m3">
            Verantwortlicher
          </a>
        </li>
        <li>
          <a className="index-link" href="#mOverview">
            Übersicht der Verarbeitungen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m11">
            Kontakt Datenschutzbeauftragter
          </a>
        </li>
        <li>
          <a className="index-link" href="#m13">
            Maßgebliche Rechtsgrundlagen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m27">
            Sicherheitsmaßnahmen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m225">
            Bereitstellung des Onlineangebotes und Webhosting
          </a>
        </li>
        <li>
          <a className="index-link" href="#m104">
            Blogs und Publikationsmedien
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Kontaktaufnahme
          </a>
        </li>
        <li>
          <a className="index-link" href="#m391">
            Kommunikation via Messenger
          </a>
        </li>
        <li>
          <a className="index-link" href="#m136">
            Präsenzen in sozialen Netzwerken (Social Media)
          </a>
        </li>
        <li>
          <a className="index-link" href="#m328">
            Plugins und eingebettete Funktionen sowie Inhalte
          </a>
        </li>
        <li>
          <a className="index-link" href="#m12">
            Löschung von Daten
          </a>
        </li>
        <li>
          <a className="index-link" href="#m15">
            Änderung und Aktualisierung der Datenschutzerklärung
          </a>
        </li>
        <li>
          <a className="index-link" href="#m10">
            Rechte der betroffenen Personen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m42">
            Begriffsdefinitionen
          </a>
        </li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>
        Lumpp, Thomas
        <br />
        Grünewaldstr. 38B
        <br />
        70192 Stuttgart
      </p>
      <p>
        E-Mail-Adresse: <a href="mailto:info@thomaslumpp.de">info@thomaslumpp.de</a>.
      </p>
      <h2 id="m11">Kontakt Datenschutzbeauftragter</h2>
      <p>
        <a href="mailto:info@thomaslumpp.de">info@thomaslumpp.de</a>
      </p>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen
        und verweist auf die betroffenen Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>
          Event-Daten (Facebook) (&quot;Event-Daten&quot; sind Daten, die z. B. via Facebook-Pixel (via Apps oder auf
          anderen Wegen) von uns an Facebook übermittelt werden können und sich auf Personen oder deren Handlungen
          beziehen; Zu den Daten gehören z. B. Angaben über Besuche auf Websites, Interaktionen mit Inhalten,
          Funktionen, Installationen von Apps, Käufe von Produkten, etc.; die Event-Daten werden zwecks Bildung von
          Zielgruppen für Inhalte und Werbeinformationen (Custom Audiences) verarbeitet; Event Daten beinhalten nicht
          die eigentlichen Inhalte (wie z. B. verfasste Kommentare), keine Login-Informationen und keine
          Kontaktinformationen (also keine Namen, E-Mail-Adressen und Telefonnummern). Event Daten werden durch Facebook
          nach maximal zwei Jahren gelöscht, die aus ihnen gebildeten Zielgruppen mit der Löschung unseres
          Facebook-Kontos).
        </li>
        <li>Bestandsdaten (z. B. Namen, Adressen).</li>
        <li>Inhaltsdaten (z. B. Eingaben in Onlineformularen).</li>
        <li>Kontaktdaten (z. B. E-Mail, Telefonnummern).</li>
        <li>Meta-/Kommunikationsdaten (z. B. Geräte-Informationen, IP-Adressen).</li>
        <li>Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Kommunikationspartner.</li>
        <li>Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
        <li>Direktmarketing (z. B. per E-Mail oder postalisch).</li>
        <li>Feedback (z. B. Sammeln von Feedback via Online-Formular).</li>
        <li>Marketing.</li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Profile mit nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Erbringung vertragliche Leistungen und Kundenservice.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
      </ul>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogenen
        Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
        speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO)</strong> - Die betroffene Person hat ihre Einwilligung
          in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere
          bestimmte Zwecke gegeben.
        </li>
        <li>
          <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</strong> - Die
          Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur
          Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
        </li>
        <li>
          <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die Verarbeitung ist zur Wahrung
          der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen
          oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
          überwiegen.
        </li>
      </ul>
      <p>
        <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den Datenschutzregelungen der
        Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört
        insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
        (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum
        Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
        Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
        Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
        Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
        Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
        Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
        natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
        Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten
        durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
        der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
        Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf
        die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits
        bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
      </p>
      <p>
        <strong>Kürzung der IP-Adresse</strong>: Sofern IP-Adressen von uns oder von den eingesetzten Dienstleistern und
        Technologien verarbeitet werden und die Verarbeitung einer vollständigen IP-Adresse nicht erforderlich ist, wird
        die IP-Adresse gekürzt (auch als &quot;IP-Masking&quot; bezeichnet). Hierbei werden die letzten beiden Ziffern,
        bzw. der letzte Teil der IP-Adresse nach einem Punkt entfernt, bzw. durch Platzhalter ersetzt. Mit der Kürzung
        der IP-Adresse soll die Identifizierung einer Person anhand ihrer IP-Adresse verhindert oder wesentlich
        erschwert werden.
      </p>
      <p>
        <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten Daten zu schützen,
        nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in
        der Adresszeile Ihres Browsers.
      </p>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder
        mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
        Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen,
        Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische
        Wartungsleistungen in Anspruch nehmen.
      </p>
      <p>
        Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres
        Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu
        gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern
        zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.
      </p>
      <p>
        <strong>E-Mail-Versand und -Hosting</strong>: Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen
        ebenfalls den Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der
        Empfänger sowie Absender als auch weitere Informationen betreffend den E-Mailversand (z. B. die beteiligten
        Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken
        der Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich
        nicht verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber
        (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von
        denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails zwischen dem
        Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen.
      </p>
      <p>
        <strong>Erhebung von Zugriffsdaten und Logfiles</strong>: Wir selbst (bzw. unser Webhostinganbieter) erheben
        Daten zu jedem Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und
        Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
        erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
        Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
      </p>
      <p>
        Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B., um eine Überlastung der
        Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
        anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z. B. Eingaben in Onlineformularen), Nutzungsdaten (z.
          B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z. B.
          Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m104">Blogs und Publikationsmedien</h2>
      <p>
        Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und Publikation (nachfolgend
        &quot;Publikationsmedium&quot;). Die Daten der Leser werden für die Zwecke des Publikationsmediums nur insoweit
        verarbeitet, als es für dessen Darstellung und die Kommunikation zwischen Autoren und Lesern oder aus Gründen
        der Sicherheit erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur Verarbeitung der Besucher
        unseres Publikationsmediums im Rahmen dieser Datenschutzhinweise.
      </p>
      <p>
        <strong>Kommentare und Beiträge</strong>: Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können
        ihre IP-Adressen auf Grundlage unserer berechtigten Interessen gespeichert werden. Das erfolgt zu unserer
        Sicherheit, falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen,
        verbotene politische Propaganda etc.). In diesem Fall können wir selbst für den Kommentar oder Beitrag belangt
        werden und sind daher an der Identität des Verfassers interessiert.
      </p>
      <p>
        Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten Interessen die Angaben der Nutzer zwecks
        Spamerkennung zu verarbeiten.
      </p>
      <p>
        Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen die IP-Adressen der Nutzer für deren
        Dauer zu speichern und Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden.
      </p>
      <p>
        Die im Rahmen der Kommentare und Beiträge mitgeteilten Informationen zur Person, etwaige Kontakt- sowie
        Webseiteninformationen als auch die inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer dauerhaft
        gespeichert.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen), Kontaktdaten (z. B. E-Mail,
          Telefonnummern), Inhaltsdaten (z. B. Eingaben in Onlineformularen), Nutzungsdaten (z. B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z. B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertragliche Leistungen und Kundenservice, Feedback (z.
          B. Sammeln von Feedback via Online-Formular), Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
          DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m182">Kontaktaufnahme</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z. B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) werden die
        Angaben der anfragenden Personen verarbeitet, soweit dies zur Beantwortung der Kontaktanfragen und etwaiger
        angefragter Maßnahmen erforderlich ist.
      </p>
      <p>
        Die Beantwortung der Kontaktanfragen im Rahmen von vertraglichen oder vorvertraglichen Beziehungen erfolgt zur
        Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen und im Übrigen
        auf Grundlage der berechtigten Interessen an der Beantwortung der Anfragen.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen), Kontaktdaten (z. B. E-Mail,
          Telefonnummern), Inhaltsdaten (z. B. Eingaben in Onlineformularen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
          DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <h2 id="m391">Kommunikation via Messenger</h2>
      <p>
        Wir setzen zu Zwecken der Kommunikation Messenger ein und bitten daher darum, die nachfolgenden Hinweise zur
        Funktionsfähigkeit der Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der Kommunikation und zu Ihren
        Widerspruchsmöglichkeiten zu beachten.
      </p>
      <p>
        Sie können uns auch auf alternativen Wegen, z. B. via Telefon oder E-Mail, kontaktieren. Bitte nutzen Sie die
        Ihnen mitgeteilten Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes angegebenen
        Kontaktmöglichkeiten.
      </p>
      <p>
        Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt Ihrer Nachricht und Anhänge) weisen
        wir darauf hin, dass die Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder) von Ende
        zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der Nachrichten nicht einsehbar ist, nicht einmal
        durch die Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der Messenger mit aktivierter
        Verschlüsselung nutzen, damit die Verschlüsselung der Nachrichteninhalte sichergestellt ist.{' '}
      </p>
      <p>
        Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass die Anbieter der Messenger zwar nicht
        den Inhalt einsehen, aber in Erfahrung bringen können, dass und wann Kommunikationspartner mit uns kommunizieren
        sowie technische Informationen zum verwendeten Gerät der Kommunikationspartner und je nach Einstellungen ihres
        Gerätes auch Standortinformationen (sogenannte Metadaten) verarbeitet werden.
      </p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen: </strong> Sofern wir Kommunikationspartner vor der Kommunikation mit ihnen
        via Messenger um eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten deren
        Einwilligung. Im Übrigen, falls wir nicht um eine Einwilligung bitten und sie z. B. von sich aus Kontakt mit uns
        aufnehmen, nutzen wir Messenger im Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung
        als eine vertragliche Maßnahme und im Fall anderer Interessenten und Kommunikationspartner auf Grundlage unserer
        berechtigten Interessen an einer schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
        Kommunikationspartner an der Kommunikation via Messenger. Ferner weisen wir Sie darauf hin, dass wir die uns
        mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an die Messenger übermitteln.
      </p>
      <p>
        <strong>Widerruf, Widerspruch und Löschung:</strong> Sie können jederzeit eine erteilte Einwilligung widerrufen
        und der Kommunikation mit uns via Messenger jederzeit widersprechen. Im Fall der Kommunikation via Messenger
        löschen wir die Nachrichten entsprechend unseren generellen Löschrichtlinien (d.h. z. B., wie oben beschrieben,
        nach Ende vertraglicher Beziehungen, im Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon
        ausgehen können, etwaige Auskünfte der Kommunikationspartner beantwortet zu haben, wenn kein Rückbezug auf eine
        vorhergehende Konversation zu erwarten ist und der Löschung keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen.
      </p>
      <p>
        <strong>Vorbehalt des Verweises auf andere Kommunikationswege:</strong> Zum Abschluss möchten wir darauf
        hinweisen, dass wir uns aus Gründen Ihrer Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten.
        Das ist der Fall, wenn z. B. Vertragsinterna besonderer Geheimhaltung bedürfen oder eine Antwort über Messenger
        den formellen Ansprüchen nicht genügt. In solchen Fällen verweisen wir Sie auf adäquatere Kommunikationswege.
      </p>
      <p>
        <strong>Skype</strong>: Die Ende-zu-Ende-Verschlüsselung von Skype setzt deren Aktivierung voraus (sofern sie
        nicht standardmäßig aktiviert sein sollte).
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail, Telefonnummern), Nutzungsdaten (z. B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z. B.
          Geräte-Informationen, IP-Adressen), Inhaltsdaten (z. B. Eingaben in Onlineformularen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Direktmarketing (z. B. per E-Mail
          oder postalisch).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen
          (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p>
        <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Facebook-Messenger:</strong> Facebook-Messenger mit Ende-zu-Ende-Verschlüsselung (die
          Ende-zu-Ende-Verschlüsselung des Facebook-Messengers setzt eine Aktivierung voraus, sofern sie nicht
          standardmäßig aktiviert sein sollte); Dienstanbieter:{' '}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com
          </a>
          , Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen:
          Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:{' '}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/about/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out):{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/settings?tab=ads
          </a>
          .
        </li>
        <li>
          <strong>Skype:</strong> Skype Messenger mit Ende-zu-Ende-Verschlüsselung; Dienstanbieter: Microsoft
          Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA; Website:{' '}
          <a href="https://www.skype.com/de/" target="_blank" rel="noopener noreferrer">
            https://www.skype.com/de/
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://privacy.microsoft.com/de-de/privacystatement" target="_blank" rel="noopener noreferrer">
            https://privacy.microsoft.com/de-de/privacystatement
          </a>
          , Sicherheitshinweise:{' '}
          <a href="https://www.microsoft.com/de-de/trustcenter" target="_blank" rel="noopener noreferrer">
            https://www.microsoft.com/de-de/trustcenter
          </a>
          .
        </li>
      </ul>
      <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2>
      <p>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der Nutzer,
        um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.
      </p>
      <p>
        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
        werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z. B. die Durchsetzung der Rechte
        der Nutzer erschwert werden könnte.
      </p>
      <p>
        Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und
        Werbezwecke verarbeitet. So können z. B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der
        Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z. B.
        Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer
        entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
        Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch
        Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer
        Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
      </p>
      <p>
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
        verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
      </p>
      <p>
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass
        diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff
        auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
        dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
      </p>
      <p>
        <strong>Facebook</strong>: Wir sind gemeinsam mit Facebook Irland Ltd. für die Erhebung (jedoch nicht die
        weitere Verarbeitung) von Daten der Besucher unserer Facebook-Seite (sog. &quot;Fanpage&quot;) verantwortlich.
        Zu diesen Daten gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen oder mit denen sie
        interagieren, oder die von ihnen vorgenommenen Handlungen (siehe unter „Von dir und anderen getätigte und
        bereitgestellte Dinge“ in der Facebook-Datenrichtlinie:{' '}
        <a href="https://www.facebook.com/policy" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/policy
        </a>
        ), sowie Informationen über die von den Nutzern genutzten Geräte (z. B. IP-Adressen, Betriebssystem, Browsertyp,
        Spracheinstellungen, Cookie-Daten; siehe unter „Geräteinformationen“ in der Facebook-Datenrichtlinie-erklärung:{' '}
        <a href="https://www.facebook.com/policy" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/policy
        </a>
        ). Wie in der Facebook-Datenrichtlinie unter „Wie verwenden wir diese Informationen?“ erläutert, erhebt und
        verwendet Facebook Informationen auch, um Analysedienste, so genannte &quot;Seiten-Insights&quot;, für
        Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber erhalten, wie Personen mit ihren Seiten und
        mit den mit ihnen verbundenen Inhalten interagieren. Wir haben mit Facebook eine spezielle Vereinbarung
        abgeschlossen (&quot;Informationen zu Seiten-Insights&quot;,{' '}
        <a
          href="https://www.facebook.com/legal/terms/page_controller_addendum"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/page_controller_addendum
        </a>
        ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook beachten muss und in der Facebook
        sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder
        Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
        Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook nicht
        eingeschränkt. Weitere Hinweise finden sich in den &quot;Informationen zu Seiten-Insights&quot; (
        <a
          href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/legal/terms/information_about_page_insights_data
        </a>
        ).
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail, Telefonnummern), Inhaltsdaten (z. B.
          Eingaben in Onlineformularen), Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten), Meta-/Kommunikationsdaten (z. B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation, Feedback (z. B. Sammeln von
          Feedback via Online-Formular), Marketing.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
        </li>
      </ul>
      <p>
        <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Instagram:</strong> Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601 Willow Road, Menlo Park,
          CA, 94025, USA, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:{' '}
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://instagram.com/about/legal/privacy" target="_blank" rel="noopener noreferrer">
            https://instagram.com/about/legal/privacy
          </a>
          .
        </li>
        <li>
          <strong>Facebook:</strong> Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA;
          Website:{' '}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/about/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen:{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/settings?tab=ads
          </a>
          .
        </li>
        <li>
          <strong>LinkedIn:</strong> Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland Unlimited Company, Wilton
          Place, Dublin 2, Irland; Website:{' '}
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
            https://www.linkedin.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
            https://www.linkedin.com/legal/privacy-policy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out):{' '}
          <a
            href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
          </a>
          .
        </li>
        <li>
          <strong>YouTube:</strong> Soziales Netzwerk und Videoplattform; Dienstanbieter: Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA; Datenschutzerklärung:{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out):{' '}
          <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
            https://adssettings.google.com/authenticated
          </a>
          .
        </li>
        <li>
          <strong>Xing:</strong> Soziales Netzwerk; Dienstanbieter: XING AG, Dammtorstraße 29-32, 20354 Hamburg,
          Deutschland; Website:{' '}
          <a href="https://www.xing.de" target="_blank" rel="noopener noreferrer">
            https://www.xing.de
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://privacy.xing.com/de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
            https://privacy.xing.com/de/datenschutzerklaerung
          </a>
          .
        </li>
      </ul>
      <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
      <p>
        Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen
        Anbieter (nachfolgend bezeichnet als &quot;Drittanbieter&quot;) bezogen werden. Dabei kann es sich zum Beispiel
        um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich bezeichnet als &quot;Inhalte&quot;).
      </p>
      <p>
        Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten,
        da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren
        jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner
        sogenannte Pixel-Tags (unsichtbare Grafiken, auch als &quot;Web Beacons&quot; bezeichnet) für statistische oder
        Marketingzwecke verwenden. Durch die &quot;Pixel-Tags&quot; können Informationen, wie der Besucherverkehr auf
        den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
        Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum
        Betriebssystem, zu verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres
        Onlineangebotes enthalten als auch mit solchen Informationen aus anderen Quellen verbunden werden.
      </p>
      <p>
        <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um deren Einwilligung in den Einsatz der
        Drittanbieter bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die
        Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen
        und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
        Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
      </p>
      <p>
        <strong>Facebook-Plugins und -Inhalte</strong>: Wir sind gemeinsam mit Facebook Irland Ltd. für die Erhebung
        oder den Erhalt im Rahmen einer Übermittlung (jedoch nicht die weitere Verarbeitung) von
        &quot;Event-Daten&quot;, die Facebook mittels der Facebook-Social-Plugins (und Einbettungsfunktionen für
        Inhalte), die auf unserem Onlineangebot ausgeführt werden, erhebt oder im Rahmen einer Übermittlung zu folgenden
        Zwecken erhält, gemeinsam verantwortlich: a) Anzeige von Inhalten sowie Werbeinformationen, die den mutmaßlichen
        Interessen der Nutzer entsprechen; b) Zustellung kommerzieller und transaktionsbezogener Nachrichten (z. B.
        Ansprache von Nutzern via Facebook-Messenger); c) Verbesserung der Anzeigenauslieferung und Personalisierung von
        Funktionen und Inhalten (z. B. Verbesserung der Erkennung, welche Inhalte oder Werbeinformationen mutmaßlich den
        Interessen der Nutzer entsprechen). Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen
        (&quot;Zusatz für Verantwortliche&quot;,{' '}
        <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/controller_addendum
        </a>
        ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook beachten muss (
        <a href="https://www.facebook.com/legal/terms/data_security_terms" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/data_security_terms
        </a>
        ) und in der Facebook sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B.
        Auskünfte oder Löschungsanfragen direkt an Facebook richten). Hinweis: Wenn Facebook uns Messwerte, Analysen und
        Berichte bereitstellt (die aggregiert sind, d. h. keine Angaben zu einzelnen Nutzern erhalten und für uns anonym
        sind), dann erfolgt diese Verarbeitung nicht im Rahmen der gemeinsamen Verantwortlichkeit, sondern auf Grundlage
        eines Auftragsverarbeitungsvertrages (&quot;Datenverarbeitungsbedingungen&quot;,{' '}
        <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/dataprocessing
        </a>
        ) , der &quot;Datensicherheitsbedingungen&quot; (
        <a href="https://www.facebook.com/legal/terms/data_security_terms" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/data_security_terms
        </a>
        ) sowie im Hinblick auf die Verarbeitung in den USA auf Grundlage von Standardvertragsklauseln
        (&quot;Facebook-EU-Datenübermittlungszusatz&quot;,{' '}
        <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/EU_data_transfer_addendum
        </a>
        ). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger
        Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook nicht eingeschränkt.
      </p>
      <p>
        <strong>Instagram-Plugins und -Inhalte</strong>: Wir sind gemeinsam mit Facebook Irland Ltd. für die Erhebung
        oder den Erhalt im Rahmen einer Übermittlung (jedoch nicht die weitere Verarbeitung) von
        &quot;Event-Daten&quot;, die Facebook mittels Funktionen von Instagram (z. B. Einbettungsfunktionen für
        Inhalte), die auf unserem Onlineangebot ausgeführt werden, erhebt oder im Rahmen einer Übermittlung zu folgenden
        Zwecken erhält, gemeinsam verantwortlich: a) Anzeige von Inhalten sowie Werbeinformationen, die den mutmaßlichen
        Interessen der Nutzer entsprechen; b) Zustellung kommerzieller und transaktionsbezogener Nachrichten (z. B.
        Ansprache von Nutzern via Facebook-Messenger); c) Verbesserung der Anzeigenauslieferung und Personalisierung von
        Funktionen und Inhalten (z. B. Verbesserung der Erkennung, welche Inhalte oder Werbeinformationen mutmaßlich den
        Interessen der Nutzer entsprechen). Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen
        (&quot;Zusatz für Verantwortliche&quot;,{' '}
        <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/controller_addendum
        </a>
        ), in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook beachten muss (
        <a href="https://www.facebook.com/legal/terms/data_security_terms" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/data_security_terms
        </a>
        ) und in der Facebook sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B.
        Auskünfte oder Löschungsanfragen direkt an Facebook richten). Hinweis: Wenn Facebook uns Messwerte, Analysen und
        Berichte bereitstellt (die aggregiert sind, d. h. keine Angaben zu einzelnen Nutzern erhalten und für uns anonym
        sind), dann erfolgt diese Verarbeitung nicht im Rahmen der gemeinsamen Verantwortlichkeit, sondern auf Grundlage
        eines Auftragsverarbeitungsvertrages (&quot;Datenverarbeitungsbedingungen&quot;,{' '}
        <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/dataprocessing
        </a>
        ) , der &quot;Datensicherheitsbedingungen&quot; (
        <a href="https://www.facebook.com/legal/terms/data_security_terms" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/terms/data_security_terms
        </a>
        ) sowie im Hinblick auf die Verarbeitung in den USA auf Grundlage von Standardvertragsklauseln
        (&quot;Facebook-EU-Datenübermittlungszusatz&quot;,{' '}
        <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">
          https://www.facebook.com/legal/EU_data_transfer_addendum
        </a>
        ). Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung, Widerspruch und Beschwerde bei zuständiger
        Aufsichtsbehörde), werden durch die Vereinbarungen mit Facebook nicht eingeschränkt.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten), Meta-/Kommunikationsdaten (z. B. Geräte-Informationen, IP-Adressen), Event-Daten (Facebook)
          (&quot;Event-Daten&quot; sind Daten, die z. B. via Facebook-Pixel (via Apps oder auf anderen Wegen) von uns an
          Facebook übermittelt werden können und sich auf Personen oder deren Handlungen beziehen; Zu den Daten gehören
          z. B. Angaben über Besuche auf Websites, Interaktionen mit Inhalten, Funktionen, Installationen von Apps,
          Käufe von Produkten, etc.; die Event-Daten werden zwecks Bildung von Zielgruppen für Inhalte und
          Werbeinformationen (Custom Audiences) verarbeitet; Event Daten beinhalten nicht die eigentlichen Inhalte (wie
          z. B. verfasste Kommentare), keine Login-Informationen und keine Kontaktinformationen (also keine Namen,
          E-Mail-Adressen und Telefonnummern). Event Daten werden durch Facebook nach maximal zwei Jahren gelöscht, die
          aus ihnen gebildeten Zielgruppen mit der Löschung unseres Facebook-Kontos), Kontaktdaten (z. B. E-Mail,
          Telefonnummern), Inhaltsdaten (z. B. Eingaben in Onlineformularen), Bestandsdaten (z. B. Namen, Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit,
          Erbringung vertragliche Leistungen und Kundenservice, Profile mit nutzerbezogenen Informationen (Erstellen von
          Nutzerprofilen), Marketing, Feedback (z. B. Sammeln von Feedback via Online-Formular), Sicherheitsmaßnahmen,
          Verwaltung und Beantwortung von Anfragen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO), Einwilligung
          (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b.
          DSGVO).
        </li>
      </ul>
      <p>
        <strong>Eingesetzte Dienste und Diensteanbieter:</strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Facebook-Plugins und -Inhalte:</strong> Facebook Social Plugins und Inhalte - Hierzu können z. B.
          Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses
          Onlineangebotes innerhalb von Facebook teilen können. Die Liste und das Aussehen der Facebook Social Plugins
          können hier eingesehen werden:{' '}
          <a href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="noopener noreferrer">
            https://developers.facebook.com/docs/plugins/
          </a>
          ; Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
          Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:{' '}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/about/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out): Einstellungen für Werbeanzeigen:{' '}
          <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">
            https://www.facebook.com/settings?tab=ads
          </a>
          .
        </li>
        <li>
          <strong>Google Fonts:</strong> Wir binden die Schriftarten (&quot;Google Fonts&quot;) des Anbieters Google
          ein, wobei die Daten der Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer
          verwendet werden. Die Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch
          sicheren, wartungsfreien und effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter
          Berücksichtigung möglicher lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google
          Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
          <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">
            https://fonts.google.com/
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          .
        </li>
        <li>
          <strong>Google Maps:</strong> Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google ein.
          Zu den verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch
          nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben
          werden; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
          Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:{' '}
          <a href="https://cloud.google.com/maps-platform" target="_blank" rel="noopener noreferrer">
            https://cloud.google.com/maps-platform
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
          <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
            https://adssettings.google.com/authenticated
          </a>
          .
        </li>
        <li>
          <strong>Inhalte von Getty Images:</strong> Einbindung von Getty Images - Bildern; Dienstanbieter: Getty
          Images, Inc., 605 5th Avenue South, Suite 400, Seattle, Washington 98104, USA; Website:{' '}
          <a href="https://www.gettyimages.de" target="_blank" rel="noopener noreferrer">
            https://www.gettyimages.de
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.gettyimages.de/company/privacy-policy" target="_blank" rel="noopener noreferrer">
            https://www.gettyimages.de/company/privacy-policy
          </a>
          .
        </li>
        <li>
          <strong>Instagram-Plugins und -Inhalte:</strong> Instagram Plugins und -Inhalte - Hierzu können z. B. Inhalte
          wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
          innerhalb von Instagram teilen können. Dienstanbieter:{' '}
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com
          </a>
          , Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA; Website:{' '}
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://instagram.com/about/legal/privacy" target="_blank" rel="noopener noreferrer">
            https://instagram.com/about/legal/privacy
          </a>
          .
        </li>
        <li>
          <strong>Spotify Music Player Widget:</strong> Spotify Music Player Widget; Dienstanbieter: Spotify AB,
          Regeringsgatan 19, SE-111 53 Stockholm, Schweden; Website:{' '}
          <a href="https://www.spotify.com/de" target="_blank" rel="noopener noreferrer">
            https://www.spotify.com/de
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://www.spotify.com/de/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">
            https://www.spotify.com/de/legal/privacy-policy/
          </a>
          .
        </li>
        <li>
          <strong>YouTube-Videos:</strong> Videoinhalte; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
          USA; Website:{' '}
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            https://www.youtube.com
          </a>
          ; Datenschutzerklärung:{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          ; Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          , Einstellungen für die Darstellung von Werbeeinblendungen:{' '}
          <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
            https://adssettings.google.com/authenticated
          </a>
          .
        </li>
      </ul>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
        Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z. B., wenn der
        Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).
      </p>
      <p>
        Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
        wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke
        verarbeitet. Das gilt z. B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen
        oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
        Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
      </p>
      <p>
        Unsere Datenschutzhinweise können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten beinhalten,
        die für die jeweiligen Verarbeitungen vorrangig gelten.
      </p>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
        Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
        machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z. B.
        Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <p>
        Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen
        angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor
        Kontaktaufnahme zu prüfen.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21
        DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong>
            Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
            1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
            gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit
            es mit solcher Direktwerbung in Verbindung steht.
          </strong>
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit zu
          widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende
          Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten
          entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
          Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
          verlangen.
        </li>
        <li>
          <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der
          gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
          alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns
          bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
          verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
          mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
        </li>
      </ul>
      <h2 id="m42">Begriffsdefinitionen</h2>
      <p>
        In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
        Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
        gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
        Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
      </p>
      <ul className="glossary">
        <li>
          <strong>Personenbezogene Daten:</strong> &quot;Personenbezogene Daten&quot; sind alle Informationen, die sich
          auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden &quot;betroffene Person&quot;)
          beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer
          Online-Kennung (z. B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
          Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
          sozialen Identität dieser natürlichen Person sind.{' '}
        </li>
        <li>
          <strong>Profile mit nutzerbezogenen Informationen:</strong> Die Verarbeitung von &quot;Profilen mit
          nutzerbezogenen Informationen&quot;, bzw. kurz &quot;Profilen&quot; umfasst jede Art der automatisierten
          Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden,
          um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen (je nach Art der Profilbildung
          können dazu unterschiedliche Informationen betreffend die Demographie, Verhalten und Interessen, wie z. B. die
          Interaktion mit Webseiten und deren Inhalten, etc.) zu analysieren, zu bewerten oder, um sie vorherzusagen (z.
          B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite oder den
          Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und Web-Beacons eingesetzt.{' '}
        </li>
        <li>
          <strong>Verantwortlicher:</strong> Als &quot;Verantwortlicher&quot; wird die natürliche oder juristische
          Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.{' '}
        </li>
        <li>
          <strong>Verarbeitung:</strong> &quot;Verarbeitung&quot; ist jeder mit oder ohne Hilfe automatisierter
          Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der
          Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das
          Speichern, das Übermitteln oder das Löschen.{' '}
        </li>
      </ul>
      <p className="seal">
        <a
          href="https://datenschutz-generator.de/?l=de"
          title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
          target="_blank"
          rel="noopener noreferrer"
        >
          Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke
        </a>
      </p>
    </Content>
  </Layout>
)

export default DatenschutzPage
